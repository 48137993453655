/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://2m9tmys21c.execute-api.us-east-2.amazonaws.com/sisecdemo",
            "region": "us-east-2"
        },
        {
            "name": "getStudies",
            "endpoint": "https://ru194jtqk1.execute-api.us-east-2.amazonaws.com/sisecdemo",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://nzdyhi4agfccbgyawigyj7sdty.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-2:962e4c59-2bbf-4a50-aebc-8d89529b0cf7",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_pqdybn5QF",
    "aws_user_pools_web_client_id": "7ngdgaomn0reio2tehvd146rar",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "sisechelpfiles130557-sisecdemo",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
